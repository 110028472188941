import * as Sentry from "@sentry/vue";
import { useRuntimeConfig } from 'nuxt/app';

async function lazyLoadSentryIntegrations() {
    // don't load on server
    if (!process.client) return;

    const { Replay } = await import("@sentry/vue");
    Sentry.addIntegration(new Replay({
        maskAllText: false,
        blockAllMedia: false,
    }));
}

function getSentryIntegrations() {
    // don't load on server
    if (!process.client) return [];

    const router = useRouter();
    const browserTracing = new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    });

    return [browserTracing];
}

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp;

        const config = useRuntimeConfig();

        const env = useRuntimeConfig().public.API_ENV;



        Sentry.init({
            app: vueApp,
            dsn: env === 'production' ? "https://327428e30a1dd081c8d53b73d886b279@us.sentry.io/4506698994417664" : env === 'staging' ? "https://fc815ab91bf5ebddb49edaa094c3d9d2@us.sentry.io/4506698986618880" : "https://23c04cbd7ce4e492708da9ca43fc395d@o4506694666813440.ingest.sentry.io/4506695282130944",
            integrations: getSentryIntegrations(),
            hooks: ['activate', 'create', 'destroy', 'mount', 'update'],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: .1 as number,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // This sets the sample rate. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: 1 as number,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: 1 as number,
        });

        nuxtApp.vueApp.config.errorHandler = (err, context) => {
            Sentry.withScope((scope) => {
              scope.setExtra('context', context)
              Sentry.captureException(err)
            })
        }

        console.log(nuxtApp.vueApp.config);
        

        // Lazy-load the replay integration to reduce bundle size
        lazyLoadSentryIntegrations();

    }
});