import { useToast } from 'vue-toast-notification';
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
    const toast = useToast();
    nuxtApp.hook('apollo:error', (error) => {
        console.log(error)
        if (!error.graphQLErrors && error.networkError) {
            if (error.networkError.response && error.networkError.response.status === 401) {
                toast.error('Vous n\’avez pas accès à cette page', {
                    position: 'top'
                })
            }else if (error.networkError.message === "Failed to fetch") {
                toast.error('Connexion interrompue', {
                    position: 'top'
                })
            } else {
                toast.error('Une erreur est survenue', {
                    position: 'top'
                })
            }       
        }else if (!error.graphQLErrors || !error.graphQLErrors[0].path[0]) {
            toast.error('Une erreur est survenue', {
                position: 'top'
            })
            Sentry.captureException(error);
        } else {
            let errorPath = error.graphQLErrors[0].path[0]
            switch (errorPath) {
                case "Login":
                    toast.error('Indentifiant ou mot de passe incorrect', {
                        position: 'top'
                    })
                    break;
                case "SignUp":
                    if (error.response.errors[0].message === "User already exists") {
                        toast.error('Ce compte existe déjà', {
                            position: 'top'
                        })
                    } else {
                        toast.error('Identifiants invalides', {
                            position: 'top'
                        })
                    }
                    break;
                case "UploadFile":
                    toast.error('Connexion insuffisante : vos images n\’ont pas pû être envoyées', {
                        position: 'top'
                    })
                    break;
                case "CreateWord":
                    toast.error('Le volume d\’images a empêché la génération du rapport', {
                        position: 'top'
                    })
                    break;
                default:
                    toast.error('Une erreur est survenue', {
                        position: 'top'
                    })
                    Sentry.captureException(error);
                    break;
            }
        }
        
    })
})
