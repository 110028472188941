import {defineRule, configure, } from "vee-validate";
import {required, email, min, max, regex, numeric} from '@vee-validate/rules';
import * as rules from "@vee-validate/rules";
import {localize} from '@vee-validate/i18n';

export default defineNuxtPlugin((nuxtApp) => {
    defineRule('required', required);
    defineRule('email', email);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('regex', regex);
    defineRule('numeric', regex);

    configure({
        validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
        validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
        validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
        validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
        generateMessage: localize({
            en: {
                messages: {
                    required: 'This field is required',
                },
            },
            fr: {
                messages: {
                    required: 'Ce champ est obligatoire',
                },
            },
        }),
    });
});
