export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"name":"mobile-web-app-capable","content":"yes","key":"mobile-web-app-capable"},{"name":"apple-mobile-web-app-title","content":"Operys PWA","key":"apple-mobile-web-app-title"},{"name":"theme-color","content":"#3D8A70","key":"theme-color"},{"property":"og:type","content":"website","key":"og:type"},{"property":"og:title","content":"Operys PWA","key":"og:title"},{"property":"og:site_name","content":"Operys","key":"og:site_name"},{"name":"twitter:card","content":"summary","key":"twitter:card"}],"link":[{"rel":"stylesheet","href":"https://rsms.me/inter/inter.css"},{"rel":"icon","href":"/_nuxt/icons/64x64.1947820c.png","key":"favicon"},{"rel":"apple-touch-icon","href":"/_nuxt/icons/512x512.maskable.1947820c.png","sizes":"512x512","key":"favicon-apple"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[],"noscript":[],"title":"Operys PWA","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'